<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  async mounted() {
    if (this.$route.query.token) {
      this.setAuthToken({ token: this.$route.query.token });
    }
  },
  methods: {
    ...mapActions("auth", ["setAuthToken"]),
  },
};
</script>

<style lang="scss">
@import "./style/mixin.sass";
@import "./style/roboto.css";
@import "./style/notosansTC.css";

html,
body,
#app {
  height: 100%;
  font-family: "Roboto", "Noto Sans TC";
}

div {
  box-sizing: border-box;
}

.container {
  position: relative;
  user-select: none;
  min-height: 100%;
  padding: 20px;
  background-color: #eee;

  .background1,
  .background2 {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    height: 100%;
  }
  .background1 {
    opacity: 0.3;
    background: url(./static/bg-nest@3x.png) no-repeat center / cover,
      url(./static/bg-four-color-gradient@3x.png) no-repeat center / 100% 100%;
  }
  .background2 {
    opacity: 0.9;
    background: url(./static/pulxion-logo-white.png) no-repeat 95% 98% / 20%;
  }

  .wrap {
    position: relative;
    z-index: 100;
    width: 100%;
    min-height: 100%;
    border-radius: 15px;
    background: rgba($color: #fff, $alpha: 0.3);
    box-shadow: inset $glow;
  }
}

// 超連結初始化

a {
  text-decoration: none;
  color: #000;
}

// 白色 Logo

.logo-white {
  background-image: url("./static/pulxion-logo-white.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

// 共用白色按鈕

.light-btn {
  font-weight: 600;
  color: $thecolor-dark;
  border: none;
  background-color: #eee;
  box-shadow: $shadow;
  transition-property: color, background-color, box-shadow;
  transition-duration: 0.5s;

  &:hover {
    background-color: $thecolor-normal;
    color: white;
    box-shadow: $glow;
  }
}

// 共用凸起按鈕

.float-btn {
  font-weight: 600;
  border: none;
  cursor: pointer;
  color: $thecolor-dark;
  background-color: rgba($color: #fff, $alpha: 0.2);
  box-shadow: $glow;
  transition-property: background-color box-shadow;
  transition-duration: 0.5s;

  &:hover,
  &:focus {
    background-color: #fff;
    color: $thecolor-normal;
    box-shadow: $glow-strong;
  }
}

// 共用白色輸入格

.light-input {
  font-family: inherit;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: $txtcolor-dark;
  background-color: transparent;
  letter-spacing: 0.1em;
  font-size: 16px;
  font-weight: 400;
  transition-property: color font-weight;
  transition-duration: 0.2s;

  &:focus {
    font-weight: 500;
  }
}

nav {
  z-index: 10000;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 20px;

  button {
    font-size: 14px;
    width: 60px;
    height: 40px;
    border-radius: 10%;
  }
}

@media screen and (max-width: 640px) {
  .background {
    background: url(./static/bg-pulxion-logo@3x.png) no-repeat center 104% /
        112%,
      url(./static/bg-nest@3x.png) no-repeat center / cover,
      url(./static/bg-four-color-gradient@3x.png) no-repeat center / 100% 100% !important;
  }
  .container {
    padding: 8px;
  }
  .wrap {
    background: none !important;
    box-shadow: none !important;
  }
  nav {
    padding: 10px;
  }
}

.download-btn {
  padding: 10px 20px;
  border: solid;
}


</style>

<template>
  <div id="chart2" :style="{ width: '100%', height: '100%' }"></div>
</template>

<script>
import * as echarts from "echarts";
import { onMounted, onUnmounted } from "vue";

export default {
  name: "chart2",

  props: {
    scores: { type: Array },
    dates: { type: Array },
  },

  setup(props) {
    /// 声明定义一下echart
    let echart = echarts;

    onMounted(() => {
      initChart();
    });

    onUnmounted(() => {
      echart.dispose;
    });

    // 基础配置一下Echarts
    function initChart() {
      let chart = echart.init(document.getElementById("chart2"));
      // 把配置和数据放这里
      chart.setOption({
        grid: {
          show: true,
          top: "5%",
          right: "5%",
          left: "12%",
          bottom: "13%",
          borderWidth: 0,
          borderColor: "transparent",
          backgroundColor: {
            type: "linear",
            x: 0,
            y: 0.4,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "transparent", // color at 0%
              },
              {
                offset: 0,
                color: "rgba(255,100,100,0.1)", // color at 100%
              },
            ],
            globalCoord: false, // default is false
          },
        },
        textStyle: {
          fontFamily: "Poppins",
          fontSize: 12,
        },
        xAxis: {
          type: "category",
          data: props.dates,
          axisLabel: {
            fontSize: 12,
            margin: 15,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#666",
              width: 3,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            fontWeight: "normal",
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100,
          axisLabel: {
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              color: "#ccc",
              width: 1,
            },
          },
        },
        series: [
          {
            data: props.scores,
            type: "line",
            symbol: "circle",
            symbolSize: 10,
            itemStyle: {
              color: "#2C767B",
            },
            lineStyle: {
              color: "#2C767B",
              width: 3,
            },
            smooth: false,
          },
        ],
      });
      window.onresize = function() {
        //自适应大小
        chart.resize();
      };
    }

    return { initChart };
  },
};
</script>

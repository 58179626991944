<template>
  <div>
      <a href="/login">Login</a>
  </div>
</template>

<script>

export default {
  name: 'Index',
  components: {
    
  },
  methods: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<style>
  
</style>
<template>
  <div class="container">
    <div class="background1"></div>
    <div class="background2"></div>
    <div class="wrap ">
      <nav>
        <button class="float-btn logout-btn" @click="logout()">登出</button>
        <button class="float-btn logout-btn" @click="goback()">返回</button>
      </nav>
      <div class="report">
        <div class="left">
          <div class="card info-card">
            <ul class="section basic-info-section">
              <h2>基本資料</h2>
              <li class="item basic-info-item">
                <span>姓名</span><span>{{ report.name }}</span>
              </li>
              <li class="item basic-info-item">
                <span>出生年月</span><span>{{ report.birth }}</span>
              </li>
              <li v-if="report.gender === 'male'" class="item basic-info-item">
                <span>性別</span><span>男</span>
              </li>
              <li v-else-if="report.gender === 'female'" class="item basic-info-item">
                <span>性別</span><span>女</span>
              </li>
              <li v-else class="item basic-info-item">
                <span>性別</span><span></span>
              </li>
            </ul>
            <ul class="section detection-info-section">
              <h2>檢測資料</h2>
              <li class="item detection-info-item">
                <span>檢測日期</span><span>{{ report.date }}</span>
              </li>
              <li class="item detection-info-item">
                <span>檢測時間</span><span>{{ report.time }}</span>
              </li>
              <li class="item detection-info-item">
                <span>機台編號</span><span>{{ report.machine_id }}</span>
              </li>
              <li class="item detection-info-item">
                <span>檢測地點</span><span>{{ report.location }}</span>
              </li>
            </ul>
          </div>
          <div class="card legend-card pc-vision">
            <h2>風險量化圖例</h2>
            <div class="section legend-info">
              <p>
                健康量化圖會以您檢測過程中的頸部圖像，搭配顏色色階的表示方式呈現分析結果的異常區域。<br />系統僅分析半透明藍色框內的區域，區域內顯示的顏色區塊越靠近藍色，代表未偵測到明顯異常或無異常；區域內顯示的顏色區塊越接近紅色時，代表偵測到異常或明顯異常。
              </p>
            </div>
            <div class="section legend-section">
              <img src="../static/report-legend.png"  alt="" />
            </div>
            <div class="section normal-legend-section">
              <h3><i class="icon icon-normal"></i>繼續保持</h3>
              <img src="../static/report-normal-chart.png" alt="" />
            </div>
            <div class="section attention-legend-section">
              <h3><i class="icon icon-attention"></i>注意</h3>
              <img src="../static/report-attention-chart.png" alt="" />
            </div>
          </div>
        </div>
        <div class="right">
          <div v-if="report.is_accomplished === true"  class="card risk-card">
            <div v-if="report.risk === 1" class="section risk-section">
              <h2>檢測結果</h2>
              <div class="risk-wrap">
                <div class="risk-score">
                  <i class="icon icon-normal"></i>
                  <span>繼續保持</span>
                </div>
                <div class="risk-comment">
                  <p>
                    恭喜您檢測結果為低風險族群<br />
                    如果您年齡大於 50 歲<br />
                    還是要定期做健康檢查<br />
                    並持續追蹤喔
                  </p>
                </div>
              </div>
            </div>
            <div v-else-if="report.risk === 2" class="section risk-section">
              <h2>檢測結果</h2>
              <div class="risk-wrap">
                <div class="risk-score">
                  <i class="icon icon-attention"></i>
                  <span>注意</span>
                </div>
                <div class="risk-comment">
                  <p>
                    您的檢測結果為高風險族群<br />
                    如果您年齡大於 50 歲<br />
                    建議進行門診與頸動脈超音波檢查
                  </p>
                </div>
              </div>
            </div>
            <div v-else class="section risk-section">
              <h2>檢測結果</h2>
              <div class="risk-wrap">
                <div class="risk-score">
                  <i class="icon icon-tracking"></i>
                  <span>持續追蹤</span>
                </div>
                <div class="risk-comment">
                  <p>
                    您的檢測結果為中風險族群<br />
                    如果您年齡大於 50 歲<br />
                    建議定期做健康檢查
                  </p>
                </div>
              </div>
            </div>
            <div class="section quantification-section">
              <h2>風險量化圖</h2>
              <!-- <img :src="report.chart" /> -->
              <div v-if="report.is_accomplished === true">
                <img :src="report.report_url" alt="" />
              </div>
              <center>
                <button class="float-btn logout-btn download-btn" @click="download_report()">下載報告</button>
              </center>
            </div>
          </div>
          <div class="card legend-card mobile-vision">
            <h2>風險量化圖例</h2>
            <div class="legend-wrap">
              <div class="section legend-info">
                <p>
                  健康量化圖會以您檢測過程中的頸部圖像，搭配顏色色階的表示方式呈現分析結果的異常區域。<br />系統僅分析半透明藍色框內的區域，區域內顯示的顏色區塊越靠近藍色，代表未偵測到明顯異常或無異常；區域內顯示的顏色區塊越接近紅色時，代表偵測到異常或明顯異常。
                </p>
              </div>
              <div class="section mobile-legend-section">
                <img src="../static/report-legend.png" alt="" />
              </div>
              <div class="section normal-legend-section">
                <h3><i class="icon icon-normal"></i>繼續保持</h3>
                <img src="../static/report-normal-chart.png" alt="" />
              </div>
              <div class="section attention-legend-section">
                <h3><i class="icon icon-attention"></i>注意</h3>
                <img src="../static/report-attention-chart.png" alt="" />
              </div>
            </div>
          </div>
          <div class="card history-card">
            <div class="history-mask">
              <span>歷史趨勢分析功能尚未開放</span>
            </div>
            <h2>歷史趨勢分析</h2>
            <div class="history-wrap">
              <div class="section axis-section">
                <div class="normal-axis">
                  <i class="icon icon-normal"></i>
                  <span>繼續保持</span>
                </div>
                <div class="attention-axis">
                  <i class="icon icon-attention"></i>
                  <span>注意</span>
                </div>
              </div>
              <div class="section chart-section">
                <div class="history-chart pc-vision" id="Chart">
                  <Chart :scores="report.scores" :dates="report.dates" />
                </div>
                <div class="history-chart mobile-vision" id="Chart2">
                  <ChartMobile :scores="report.scores" :dates="report.dates" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Chart from "../components/chart.vue";
import ChartMobile from "../components/chart-mobile.vue";

export default {
  name: "Reports",
  components: { Chart, ChartMobile },
  data() {
    return {
      report: {
        name: "",
        birth: "",
        gender: "",
        date: "",
        time: "",
        machine_id: "",
        location: "",
        risk: 1,
        is_accomplished: true,
        report_url: 'https://pulxion-report.s3.ap-northeast-1.amazonaws.com/blank.png',
        chart:
          "https://image.cache.storm.mg/styles/smg-800x533-fp/s3/media/image/2016/02/25/20160225-033457_U3927_M131923_2073.png?itok=fOdkVYDV",
        // 疑似因為 webpack 圖片解析問題還沒接上去 html
        scores: [30, 40, 50, 60, 70, 80],
        dates: ["03/24", "03/25", "03/26", "03/27", "03/29", "06/21"],
      },
    };
  },
  mounted() {
    this.$api.report
      .getReportDetails(this.$route.query.video_id)
      .then(async (response) => {
        console.log(this.$route.query.video_id);
        const { data } = response.data;
        data.machine_id = data.machine_id.slice(0, 5);
        data.birth = data.birth.split("-").join("/");
        console.log(data);
        this.report = data;
      })
      .catch(() => {
        console.log("error");
      });
  },
  methods: {
    ...mapMutations("auth", ["REMOVE_AUTH_TOKEN"]),
    async logout() {
      this.REMOVE_AUTH_TOKEN();
      console.log(this.token);
      this.$router.replace({ name: "Login" });
    },
    goback: function() {
      this.$router.push("/reports");
    },
    download_report: function() {
      this.$api.report
        .downloadReport({video_id: this.$route.query.video_id})
        .then(async (response) => {
          const { data } = response.data;
          window.open(data.report_url, '_blank');
        })
        .catch(() => {
          console.log("error");
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../style/mixin.sass";
.container {
  .wrap {
    .report {
      padding: 0 25px 15px;
      display: flex;
      // general
      .card {
        background: rgba(255, 255, 255, 0.85);
        padding: 20px;
        margin: 25px 15px;
        border-radius: 5px;
        box-shadow: $shadow;
        h2 {
          color: $thecolor-dark;
          font-size: 24px;
          padding: 0.6em 0;
          letter-spacing: 0.05em;
        }
        i.icon-normal {
          background: url(../static/report-normal.png) no-repeat center /
            contain;
        }
        i.icon-attention {
          background: url(../static/report-attention.png) no-repeat center /
            contain;
        }
        i.icon-tracking {
          background: url(../static/report-tracking.png) no-repeat center /
            contain;
        }
        i.icon {
          display: inline-block;
          width: 30px;
          height: 30px;
        }
      }
      .legend-card {
        background-color: rgba($color: $thecolor-normal, $alpha: 0.7);
        // box-shadow: $glow;
        h2 {
          color: white;
        }
        .section {
          padding: 10px;
          position: relative;
          img {
            width: 100%;
            height: auto;
          }
          h3 {
            i {
              position: absolute;
              left: 0;
              top: -4px;
              border: 3px solid white;
              border-radius: 50%;
            }
            position: relative;
            font-size: 20px;
            color: white;
            font-weight: 500;
            letter-spacing: 0.05em;
            padding-bottom: 0.5em;
            padding-left: 48px;
            height: 36px;
          }
          p {
            padding-top: 0.3em;
            text-align: justify;
            font-size: 13px;
            color: white;
            letter-spacing: 0.1em;
            line-height: 1.8em;
          }
        }
        .legend-info {
          padding: 0 0.5em 2em;
        }
        .normal-legend-section {
          padding-bottom: 1.5em;
        }
        .attention-legend-section,
        .normal-legend-section {
          border-top: 1px solid #ccc;
          padding-top: 2em;
        }
        .legend-section::before {
          content: "異常";
          position: absolute;
          right: 1vw;
          color: #ffffff;
          font-size: 1.0vw;
        }
        .legend-section::after {
          content: "無異常";
          position: absolute;
          left: 1vw;
          color: #ffffff;
          font-size: 1.0vw;
        }
        .mobile-legend-section::before {
          content: "異常";
          position: absolute;
          right: 5vw;
          color: #ffffff;
          font-size: 3.0vw;
        }
        .mobile-legend-section::after {
          content: "無異常";
          position: absolute;
          left: 5vw;
          color: #ffffff;
          font-size: 3.0vw;
        }
      }

      // specific
      .left {
        width: 25%;
        .info-card {
          ul.basic-info-section,
          ul.detection-info-section {
            li.item {
              height: 3em;
              span:first-child {
                padding-left: 10px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.05em;
                display: inline-block;
                width: 40%;
              }
              span:last-child {
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 0.02em;
                color: $txtcolor-dark;
              }
            }
          }
          ul.detection-info-section {
            margin-top: 1em;
          }
          ul.basic-info-section {
            padding-bottom: 1em;
            border-bottom: 1px solid #ccc;
          }
        }
      }
      .right {
        width: 75%;

        .risk-card {
          display: flex;
          .risk-section {
            width: 60%;
            .risk-wrap {
              display: flex;
              height: calc(90% - 40px);
              margin: 0 30px 10px 10px;
              box-sizing: border-box;
              background: rgba($color: #fff, $alpha: 1);
              border-radius: 20px;
              padding: 20px 0;
              border: 1px solid #eee;
              // box-shadow: $shadow;
              .risk-score {
                width: 40%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #ccc;
                padding: 20px;
                i {
                  width: 40%;
                  height: 25%;
                }
                span {
                  font-size: 2vw;
                  font-weight: 500;
                  color: $txtcolor-dark;
                  letter-spacing: 0.02em;
                  padding-top: 0.5em;
                }
              }
              .risk-comment {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60%;
                padding: 0 2em 0;
                text-align: justify;
                font-size: 19px;
                color: $txtcolor-light;
                letter-spacing: 0.05em;
                line-height: 1.8em;

                p {
                  font-weight: 400;
                }
              }
            }
          }
          .quantification-section {
            width: 40%;
            img {
              padding: 0 10px 10px;
              box-sizing: border-box;
              width: 100%;
              height: auto;
            }
          }
        }

        .mobile-vision {
          display: none;
        }

        .history-card {
          position: relative;
          .history-mask {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba($color: #331535, $alpha: 0.7);
            border-radius: 5px;
            z-index: 1000;
            span {
              display: block;
              font-size: 18px;
              font-weight: 400;
              color: white;
              letter-spacing: 0.1em;
            }
          }
          .history-wrap {
            display: flex;
            .axis-section {
              width: 15%;
              display: flex;
              flex-direction: column;
              .normal-axis,
              .attention-axis {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                span {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 2.5em;
                  color: $txtcolor-light;
                  letter-spacing: 0.1em;
                }
              }
              .normal-axis {
                height: 48%;
              }
              .attention-axis {
                height: 35%;
              }
            }
            .chart-section {
              width: 85%;
              .history-chart {
                height: 400px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .section {
    img {
      width: 100px;
      height: 100px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .left {
    width: 30% !important;
  }
  .right {
    width: 70% !important;
    .risk-card {
      .risk-wrap {
        .risk-score {
          span {
            font-size: 18px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .left {
    width: 35% !important;
  }
  .right {
    width: 65% !important;
    .risk-card {
      .risk-wrap {
        flex-direction: column !important;
        .risk-score {
          width: 100% !important;
          border-right: none !important;
          i {
            height: 30px !important;
            width: 30px !important;
          }
          span {
            font-size: 18px !important;
          }
        }
        .risk-comment {
          width: 100% !important;
        }
      }
    }
    .history-card {
      .axis-section {
        span {
          font-size: 14px !important;
        }
      }
      .chart-section {
        .history-chart {
          height: 300px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .pc-vision {
    display: none !important;
  }
  .mobile-vision {
    display: block !important;
  }

  .report {
    flex-direction: column;
    .card {
      margin: 20px 0 !important;
    }
    .right,
    .left {
      width: 100% !important;
      margin: 0;
      padding: 0;
    }
    .left {
      .info-card {
        margin-bottom: 0px !important;
        display: flex !important;
        .basic-info-section,
        .detection-info-section {
          width: 50%;
          margin: 0 10px !important;
        }
        .basic-info-section {
          border-bottom: none !important;
          border-right: 1px solid #ccc;
        }
      }
    }
    .right {
      .legend-card {
        .legend-wrap {
          display: flex;
          .section {
            margin: 0;
            padding: 10px 20px !important;
            width: 50%;
            border-top: none !important;
          }
          .legend-info {
            padding-left: 10px !important;
          }
        }
      }
    }
    .history-card {
      .chart-section {
        .history-chart {
          height: 300px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .pc-vision {
    display: none !important;
  }
  .mobile-vision {
    display: block !important;
  }

  .report {
    flex-direction: column;
    padding: 5px !important;
    .card {
      margin: 10px 0 !important;
    }
    .left {
      .info-card {
        display: block !important;
        .basic-info-section,
        .detection-info-section {
          box-sizing: border-box;
          width: 100%;
          padding: 10px !important;
          margin: 0 !important;
        }
        .basic-info-section {
          border-right: none;
          border-bottom: 1px solid #ccc !important;
        }
      }
    }
    .right {
      .risk-card {
        flex-direction: column;
        .section {
          width: 100% !important;
        }
      }
      .legend-card {
        .legend-wrap {
          flex-direction: column;
          .section {
            width: 100% !important;
          }
          .normal-legend-section {
            border-right: none;
          }
        }
      }
      .history-card {
        .axis-section {
          span {
            display: none;
          }
        }
        .history-chart {
          height: 200px !important;
        }
      }
    }
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import api from './apis';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';

import './style/reset.css'

const options = {
    confirmButtonColor: '#17A2B8',
    cancelButtonColor: '#FF4F44',
};

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueSweetalert2, options)

app.config.globalProperties.$api = api;

app.mount('#app')

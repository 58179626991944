// import { LoaderTargetPlugin } from 'webpack';
import req from './https';

const report = {
    getReports(params) {
      return req.get('/v3/users/get_reports', params);
    },
    getReportDetails(video_id) {
      return req.get('/v3/users/get_report_details?video_id=' + video_id);
    },
    downloadReport(params) {
      return req.post('/v3/users/download_report', params);
    }
}

export default report;

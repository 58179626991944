// import { LoaderTargetPlugin } from 'webpack';
import req from './https';

const auth = {
    login(params) {
        return req.post('/v3/users/login', params);
    }
}

export default auth;

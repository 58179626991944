<template>
  <div class="container">
    <div class="background1"></div>
    <div class="background2"></div>
    <div class="wrap reports-list-wrap">
      <nav>
        <button
          class="float-btn logout-btn"
          :class="{ touchColor: touched }"
          @touchstart="touchstart()"
          @touchend="touchend()"
          @click="logout()"
        >
          登出
        </button>
      </nav>
      <div class="report-list-wrap">
        <div class="thead">
          <div class="col1 col" scope="col">
            日期
          </div>
          <div class="col2 col" scope="col">
            時間
          </div>
          <div class="col3 col" scope="col">
            設備編號
          </div>
          <div class="col4 col" scope="col">
            姓名
          </div>
          <div class="col5 col" scope="col">
            狀態
          </div>
        </div>
        <ul class="tbody">
          <li v-for="(report, i) in reports" :key="i">
            <div
              class="complete list-item"
              :class="{ touchColor: touched }"
              @touchstart="touchstart()"
              @touchend="touchend()"
              @click="goTo(report.videos.video_id)"
              v-if="report.videos.is_accomplished"
            >
              <div class="col1 col">
                {{ report.date }}
              </div>
              <div class="col2 col">
                {{ report.time }}
              </div>
              <div class="col3 col">
                {{ report.videos.machine_id }}
              </div>
              <div class="col4 col">
                {{ report.user }}
              </div>
              <div class="col5 col">
                分析完成
              </div>
            </div>
            <div 
              class="complete list-item"
              :class="{ touchColor: touched }"
              @touchstart="touchstart()"
              @touchend="touchend()"
              @click="goTo(report.videos.video_id)"
              v-else
            >
              <div class="col1 col">
                {{ report.date }}
              </div>
              <div class="col2 col">
                {{ report.time }}
              </div>
              <div class="col3 col">
                {{ report.videos.machine_id }}
              </div>
              <div class="col4 col">
                {{ report.user }}
              </div>
              <div class="col5 col" v-if="report.videos.is_error">
                影片不佳
              </div>
              <div class="col5 col" v-else-if="report.videos.is_processing">
                分析中
              </div>
              <div class="col5 col" v-else-if="report.videos.is_uploaded">
                已上傳
              </div>
              <div class="col5 col" v-else>
                上傳中
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Reports",
  components: {},
  data() {
    return {
      touched: false,
      reports: [
        {
          date: "2021.07.16",
          time: "14:50:47",
          videos: {
            machine_id: "50063b72-3de6-40fc-b225-be632ede4b80",
            location: "NTU",
            is_uploaded: true,
            is_processing: true,
            is_accomplished: true,
            is_error: false,
            video_id: "230e6227-1ec2-46c4-98c1-90ab8e4d166e",
          },
        },
      ],
    };
  },
  mounted() {
    this.$api.report
      .getReports()
      .then(async (response) => {
        const { data } = response.data;
        for (let i = 0; i < data.length; i++) {
          data[i].videos.machine_id = await data[i].videos.machine_id.slice(
            0,
            5
          );
        }
        this.reports = data;
      })
      .catch((resp) => {
        console.log(resp);
      });
  },
  methods: {
    ...mapMutations("auth", ["REMOVE_AUTH_TOKEN"]),
    async logout() {
      this.REMOVE_AUTH_TOKEN();
      console.log(this.token);
      this.$router.replace({ name: "Login" });
    },
    goTo: function(videoID) {
      this.$router.push("/reportdetails?video_id=" + videoID);
    },
    touchstart() {
      this.touched = true;
    },
    touchend() {
      this.touched = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/mixin.sass";

.report-list-wrap {
  z-index: 1000;
  padding: 3vw 10vw;
  .thead,
  .tbody li .list-item {
    display: flex;
    height: 4em;
    text-align: center;
    align-items: center;
    font-weight: 400;
    box-sizing: border-box;
    padding: 0 15px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .thead {
    border-bottom: 2px solid #fff;
    font-size: 16px;
    letter-spacing: 0.2em;
    color: $thecolor-dark;
    border-radius: 5px 5px 0 0;
  }
  .tbody {
    li {
      .list-item {
        // margin: 0.6em 0;
        // border-radius: 0.2em;
        border-bottom: 1px solid #fff;
        font-size: 14px;
        color: $txtcolor-dark;
        letter-spacing: 0.1em;
        opacity: 0.3;
        border-left: none;
        border-right: none;
      }
      .complete {
        cursor: pointer;
        opacity: 1;
        transition-property: border, background-color, box-shadow;
        transition-duration: 0.5s;

        &:hover,
        &:focus {
          // border: 1px solid $thecolor-normal;
          background-color: #fff;
          box-shadow: $glow;
          color: $txtcolor-light;
        }
      }
      &:last-child {
        .list-item {
          border-radius: 0 0 5px 5px;
          border-bottom: none;
          margin-bottom: 3em;
        }
      }
    }
  }

  .col {
    width: 50%;
  }
}

@media screen and (max-width: 640px) {
  .col3,
  .col4 {
    display: none;
  }
  .container {
    .wrap {
      .report-list-wrap {
        padding: 0 0 !important;
        .thead,
        .tbody li .list-item {
          box-shadow: $glow;
        }
      }
    }
  }
}
</style>

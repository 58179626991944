import { createRouter, createWebHistory } from 'vue-router'

import store from '../store';
import Index from '../views/Index.vue';
import Login from '../views/Login.vue';
import Reports from '../views/Reports.vue';
import ReportDetails from '../views/ReportDetails.vue';


const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    beforeEnter() {
      window.location.href = "https://pulxion.com.tw/";
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: { requiresAuth: true }
  },
  {
    path: '/reportdetails',
    name: 'ReportDetails',
    component: ReportDetails,
    meta: { requiresAuth: true }
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  window.document.title = '博想醫學科技 Pulxion Medical Technology';
  const { token } = store.state.auth;
  console.log(token)
  if (to.meta.requiresAuth && !token) {
    next({ name: 'Login' });
    return;
  }
  if (token) {
    if (to.name === 'Login') {
      next({ name: 'Reports' });
      return;
    }
  }
  next();
});

export default router;
import * as axios from 'axios';
import store from '../store';

const instance = axios.default.create({
  baseURL: `${process.env.VUE_APP_SERVER_URI}`,
});

instance.interceptors.request.use((config) => {
  const { token } = store.state.auth;
  const newConfig = config;
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
}, (error) => Promise.reject(error));

instance.interceptors.response.use((response) => response, (error) => {
  const { response } = error;
  if (response) {
    // errorHandler(response);
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

export default instance;
<template>
  <div class="container">
    <!-- <div class="background"></div> -->
    <div class="wrap login-wrap">
      <div class="welcome">
        <h1>LOGIN</h1>
        <h2>Welcome Back To Pulxion.</h2>
      </div>
      <ul class="input-list">
        <li class="input-item">
          <label for="name" class="sr-only">姓名</label>
          <input
            class="light-input"
            id="name"
            v-model="loginInfo.name"
            name="name"
            autocomplete="off"
            required
          />
        </li>
        <li class="input-item">
          <label for="birth" class="sr-only">生日</label>
          <input
            class="light-input"
            type="month"
            id="birth"
            v-model="loginInfo.birth"
            name="birth"
            autocomplete="off"
            required
          />
        </li>
        <li class="input-item">
          <label for="phone" class="sr-only">電話</label>
          <input
            class="light-input"
            id="phone"
            v-model="loginInfo.phone"
            name="phone"
            autocomplete="off"
            required
          />
        </li>
      </ul>
      <button
        class="login-btn light-btn"
        type="button"
        @click="login()"
        :class="{ touchColor: touched }"
        @touchstart="touchstart()"
        @touchend="touchend(), login()"
      >
        登入
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      touched: "false",
      loginInfo: {
        name: "",
        birth: "",
        phone: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      loginInfo: {
        name: { required },
        birth: { required },
        phone: { required },
      },
    };
  },
  computed: {
    ...mapState("auth", ["token"]),
  },
  methods: {
    ...mapMutations("auth", ["SET_AUTH_TOKEN"]),
    async login() {
      if (this.v$.loginInfo.$invalid) {
        alert("基本資料為必填");
        // this.$swal.fire({
        //   icon: 'error',
        //   title: '基本資料為必填',
        //   iconColor: '#2c767b',
        //   showConfirmButton: false,
        // })
        return;
      }
      this.$api.auth
        .login(this.loginInfo)
        .then(async (response) => {
          const { data } = response.data;
          if (data.access_token) {
            console.log(data.access_token);
            this.SET_AUTH_TOKEN(data.access_token);
            alert("登入成功");
            // this.$swal.fire({
            //   icon: 'success',
            //   title: '登入成功',
            //   showConfirmButton: false,
            //   timer: 1500
            // })
            window.location.href = "/reports";
          }
        })
        .catch(() => {
          alert("登入失敗");
          // this.$swal.fire({
          //   icon: 'error',
          //   title: '登入失敗',
          //   iconColor: '#2c767b',
          //   showConfirmButton: false,
          // })
        });
    },
    touchstart() {
      this.touched = true;
    },
    touchend() {
      this.touched = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/mixin.sass";

.container {
  display: flex;
  justify-content: center;
  background: #fafafa;
}

.login-wrap {
  // border: 10px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $bg-pc;

  .welcome {
    color: #eee;
    text-align: center;
    padding-bottom: 28px;
    h1 {
      font-size: 36px;
      line-height: 1.2em;
      letter-spacing: 0.05em;
    }
    h2 {
      font-size: 16px;
      line-height: 1.2em;
      font-weight: 400;
      letter-spacing: 0.03em;
    }
  }

  ul.input-list {
    z-index: 200;
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 360px;
    background-color: #eee;
    border-radius: 40px;
    box-shadow: $glow;

    li.input-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #ccc;
      height: 40%;

      &:last-child {
        border: none;
      }

      label {
        font-size: 14px;
        line-height: 36px;
        color: $txtcolor-dark;
        font-weight: 500;
        letter-spacing: 0.1em;
      }

      input {
        width: 240px;
        height: 36px;
        margin: 0 10px;
      }
    }
  }

  .login-btn {
    z-index: 1000;
    margin-top: 24px;
    font-size: 14px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 640px) {
  .container {
    padding: 0px !important;

    .login-wrap {
      border-radius: 0 !important;

      ul.input-list {
        border-radius: 20px;
        width: 85%;
        background-color: rgba(255, 255, 255, 0.8);
        li {
          input {
            text-align: left !important;
            width: 64% !important;
          }
        }
      }
      button.login-btn {
        margin-top: 36px;
      }
    }
  }
}
</style>
